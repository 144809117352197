<template>
  <div class="app">
    <router-view></router-view>
  </div>
  <iframe
    ref="iframe"
    src="/print.html"
    width="100%"
    style="padding: 0; margin: 0; position: fixed; display: block; bottom: -100%"
    frameborder="0"
  >
  </iframe>
  <iframe
    ref="hangingtags"
    src="/hangtagprinting.html"
    width="100%"
    style="padding: 0; margin: 0; position: fixed; display: block; bottom: -100%"
    frameborder="0"
  >
  </iframe>
  <iframe
    ref="outboundprint"
    src="/outboundprinting.html"
    width="100%"
    style="padding: 0; margin: 0; position: fixed; display: block; bottom: -100%"
    frameborder="0"
  >
  </iframe>
  <iframe
    ref="transferorderPrint"
    src="/transferPrinting.html"
    width="100%"
    style="padding: 0; margin: 0; position: fixed; display: block; bottom: -100%"
    frameborder="0"
  >
  </iframe>
  <iframe
    ref="washedLabelPrint"
    src="/printWashedLabel.html"
    width="100%"
    style="padding: 0; margin: 0; position: fixed; display: block; bottom: -100%"
    frameborder="0"
  >
  </iframe>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const iframe = ref()
const hangingtags = ref()
const outboundprint = ref()
const transferorderPrint = ref()
const washedLabelPrint = ref()
const emitter = inject('emitter')
emitter.on('printInfo', (jsonObject) => {
  iframe.value.contentWindow.postMessage(
    {
      zz: 'zz',
      jsonObject
    },
    '*'
  )
})
const emitter1 = inject('emitter')
emitter.on('printt', (jsonObject) => {
  hangingtags.value.contentWindow.postMessage(
    {
      zz: 'zz',
      jsonObject
    },
    '*'
  )
})
const emitter2 = inject('emitter')
emitter.on('prionoutbound', (jsonObject) => {
  outboundprint.value.contentWindow.postMessage(
    {
      zz: 'zz',
      jsonObject
    },
    '*'
  )
})
const emitter3 = inject('emitter')
emitter.on('pringtransfer', (jsonObject) => {
  transferorderPrint.value.contentWindow.postMessage(
    {
      zz: 'zz',
      jsonObject
    },
    '*'
  )
})
emitter.on('printWashedLabel', (jsonObject) => {
  washedLabelPrint.value.contentWindow.postMessage(
    {
      zz: 'zz',
      jsonObject
    },
    '*'
  )
})
onMounted(() => {
  store
    .dispatch('fetchGlobalData')
    .then(() => {
      document.title = store.state.globalData.titleName
      // let relIcon = document.head.querySelector("link[rel='icon']")
      // relIcon.href = store.state.globalData.icoUrl
    })
    .catch((error) => {
      console.error('Failed to fetch global data:', error)
    })
})
</script>

<style lang="less" scoped>
body {
  .app {
    width: 100%;
  }
}
</style>
